@media(prefers-color-scheme: light){
pre { line-height: 125%; }
td.linenos .normal { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
span.linenos { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
td.linenos .special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
.codehilite .hll, .codehilitetable .hll { background-color: #ffffcc }
.codehilite , .codehilitetable { background: #ffffff; }
.codehilite .c, .codehilitetable .c { color: #177500 } /* Comment */
.codehilite .err, .codehilitetable .err { color: #000000 } /* Error */
.codehilite .k, .codehilitetable .k { color: #A90D91 } /* Keyword */
.codehilite .l, .codehilitetable .l { color: #1C01CE } /* Literal */
.codehilite .n, .codehilitetable .n { color: #000000 } /* Name */
.codehilite .o, .codehilitetable .o { color: #000000 } /* Operator */
.codehilite .ch, .codehilitetable .ch { color: #177500 } /* Comment.Hashbang */
.codehilite .cm, .codehilitetable .cm { color: #177500 } /* Comment.Multiline */
.codehilite .cp, .codehilitetable .cp { color: #633820 } /* Comment.Preproc */
.codehilite .cpf, .codehilitetable .cpf { color: #177500 } /* Comment.PreprocFile */
.codehilite .c1, .codehilitetable .c1 { color: #177500 } /* Comment.Single */
.codehilite .cs, .codehilitetable .cs { color: #177500 } /* Comment.Special */
.codehilite .kc, .codehilitetable .kc { color: #A90D91 } /* Keyword.Constant */
.codehilite .kd, .codehilitetable .kd { color: #A90D91 } /* Keyword.Declaration */
.codehilite .kn, .codehilitetable .kn { color: #A90D91 } /* Keyword.Namespace */
.codehilite .kp, .codehilitetable .kp { color: #A90D91 } /* Keyword.Pseudo */
.codehilite .kr, .codehilitetable .kr { color: #A90D91 } /* Keyword.Reserved */
.codehilite .kt, .codehilitetable .kt { color: #A90D91 } /* Keyword.Type */
.codehilite .ld, .codehilitetable .ld { color: #1C01CE } /* Literal.Date */
.codehilite .m, .codehilitetable .m { color: #1C01CE } /* Literal.Number */
.codehilite .s, .codehilitetable .s { color: #C41A16 } /* Literal.String */
.codehilite .na, .codehilitetable .na { color: #836C28 } /* Name.Attribute */
.codehilite .nb, .codehilitetable .nb { color: #A90D91 } /* Name.Builtin */
.codehilite .nc, .codehilitetable .nc { color: #3F6E75 } /* Name.Class */
.codehilite .no, .codehilitetable .no { color: #000000 } /* Name.Constant */
.codehilite .nd, .codehilitetable .nd { color: #000000 } /* Name.Decorator */
.codehilite .ni, .codehilitetable .ni { color: #000000 } /* Name.Entity */
.codehilite .ne, .codehilitetable .ne { color: #000000 } /* Name.Exception */
.codehilite .nf, .codehilitetable .nf { color: #000000 } /* Name.Function */
.codehilite .nl, .codehilitetable .nl { color: #000000 } /* Name.Label */
.codehilite .nn, .codehilitetable .nn { color: #000000 } /* Name.Namespace */
.codehilite .nx, .codehilitetable .nx { color: #000000 } /* Name.Other */
.codehilite .py, .codehilitetable .py { color: #000000 } /* Name.Property */
.codehilite .nt, .codehilitetable .nt { color: #000000 } /* Name.Tag */
.codehilite .nv, .codehilitetable .nv { color: #000000 } /* Name.Variable */
.codehilite .ow, .codehilitetable .ow { color: #000000 } /* Operator.Word */
.codehilite .mb, .codehilitetable .mb { color: #1C01CE } /* Literal.Number.Bin */
.codehilite .mf, .codehilitetable .mf { color: #1C01CE } /* Literal.Number.Float */
.codehilite .mh, .codehilitetable .mh { color: #1C01CE } /* Literal.Number.Hex */
.codehilite .mi, .codehilitetable .mi { color: #1C01CE } /* Literal.Number.Integer */
.codehilite .mo, .codehilitetable .mo { color: #1C01CE } /* Literal.Number.Oct */
.codehilite .sa, .codehilitetable .sa { color: #C41A16 } /* Literal.String.Affix */
.codehilite .sb, .codehilitetable .sb { color: #C41A16 } /* Literal.String.Backtick */
.codehilite .sc, .codehilitetable .sc { color: #2300CE } /* Literal.String.Char */
.codehilite .dl, .codehilitetable .dl { color: #C41A16 } /* Literal.String.Delimiter */
.codehilite .sd, .codehilitetable .sd { color: #C41A16 } /* Literal.String.Doc */
.codehilite .s2, .codehilitetable .s2 { color: #C41A16 } /* Literal.String.Double */
.codehilite .se, .codehilitetable .se { color: #C41A16 } /* Literal.String.Escape */
.codehilite .sh, .codehilitetable .sh { color: #C41A16 } /* Literal.String.Heredoc */
.codehilite .si, .codehilitetable .si { color: #C41A16 } /* Literal.String.Interpol */
.codehilite .sx, .codehilitetable .sx { color: #C41A16 } /* Literal.String.Other */
.codehilite .sr, .codehilitetable .sr { color: #C41A16 } /* Literal.String.Regex */
.codehilite .s1, .codehilitetable .s1 { color: #C41A16 } /* Literal.String.Single */
.codehilite .ss, .codehilitetable .ss { color: #C41A16 } /* Literal.String.Symbol */
.codehilite .bp, .codehilitetable .bp { color: #5B269A } /* Name.Builtin.Pseudo */
.codehilite .fm, .codehilitetable .fm { color: #000000 } /* Name.Function.Magic */
.codehilite .vc, .codehilitetable .vc { color: #000000 } /* Name.Variable.Class */
.codehilite .vg, .codehilitetable .vg { color: #000000 } /* Name.Variable.Global */
.codehilite .vi, .codehilitetable .vi { color: #000000 } /* Name.Variable.Instance */
.codehilite .vm, .codehilitetable .vm { color: #000000 } /* Name.Variable.Magic */
.codehilite .il, .codehilitetable .il { color: #1C01CE } /* Literal.Number.Integer.Long */
}
@media(prefers-color-scheme: dark){
pre { line-height: 125%; }
td.linenos .normal { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
span.linenos { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
td.linenos .special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
.codehilite .hll, .codehilitetable .hll { background-color: #2C3B41 }
.codehilite , .codehilitetable { background: #263238; color: #EEFFFF }
.codehilite .c, .codehilitetable .c { color: #546E7A; font-style: italic } /* Comment */
.codehilite .err, .codehilitetable .err { color: #FF5370 } /* Error */
.codehilite .esc, .codehilitetable .esc { color: #89DDFF } /* Escape */
.codehilite .g, .codehilitetable .g { color: #EEFFFF } /* Generic */
.codehilite .k, .codehilitetable .k { color: #BB80B3 } /* Keyword */
.codehilite .l, .codehilitetable .l { color: #C3E88D } /* Literal */
.codehilite .n, .codehilitetable .n { color: #EEFFFF } /* Name */
.codehilite .o, .codehilitetable .o { color: #89DDFF } /* Operator */
.codehilite .p, .codehilitetable .p { color: #89DDFF } /* Punctuation */
.codehilite .ch, .codehilitetable .ch { color: #546E7A; font-style: italic } /* Comment.Hashbang */
.codehilite .cm, .codehilitetable .cm { color: #546E7A; font-style: italic } /* Comment.Multiline */
.codehilite .cp, .codehilitetable .cp { color: #546E7A; font-style: italic } /* Comment.Preproc */
.codehilite .cpf, .codehilitetable .cpf { color: #546E7A; font-style: italic } /* Comment.PreprocFile */
.codehilite .c1, .codehilitetable .c1 { color: #546E7A; font-style: italic } /* Comment.Single */
.codehilite .cs, .codehilitetable .cs { color: #546E7A; font-style: italic } /* Comment.Special */
.codehilite .gd, .codehilitetable .gd { color: #FF5370 } /* Generic.Deleted */
.codehilite .ge, .codehilitetable .ge { color: #89DDFF } /* Generic.Emph */
.codehilite .gr, .codehilitetable .gr { color: #FF5370 } /* Generic.Error */
.codehilite .gh, .codehilitetable .gh { color: #C3E88D } /* Generic.Heading */
.codehilite .gi, .codehilitetable .gi { color: #C3E88D } /* Generic.Inserted */
.codehilite .go, .codehilitetable .go { color: #546E7A } /* Generic.Output */
.codehilite .gp, .codehilitetable .gp { color: #FFCB6B } /* Generic.Prompt */
.codehilite .gs, .codehilitetable .gs { color: #FF5370 } /* Generic.Strong */
.codehilite .gu, .codehilitetable .gu { color: #89DDFF } /* Generic.Subheading */
.codehilite .gt, .codehilitetable .gt { color: #FF5370 } /* Generic.Traceback */
.codehilite .kc, .codehilitetable .kc { color: #89DDFF } /* Keyword.Constant */
.codehilite .kd, .codehilitetable .kd { color: #BB80B3 } /* Keyword.Declaration */
.codehilite .kn, .codehilitetable .kn { color: #89DDFF; font-style: italic } /* Keyword.Namespace */
.codehilite .kp, .codehilitetable .kp { color: #89DDFF } /* Keyword.Pseudo */
.codehilite .kr, .codehilitetable .kr { color: #BB80B3 } /* Keyword.Reserved */
.codehilite .kt, .codehilitetable .kt { color: #BB80B3 } /* Keyword.Type */
.codehilite .ld, .codehilitetable .ld { color: #C3E88D } /* Literal.Date */
.codehilite .m, .codehilitetable .m { color: #F78C6C } /* Literal.Number */
.codehilite .s, .codehilitetable .s { color: #C3E88D } /* Literal.String */
.codehilite .na, .codehilitetable .na { color: #BB80B3 } /* Name.Attribute */
.codehilite .nb, .codehilitetable .nb { color: #82AAFF } /* Name.Builtin */
.codehilite .nc, .codehilitetable .nc { color: #FFCB6B } /* Name.Class */
.codehilite .no, .codehilitetable .no { color: #EEFFFF } /* Name.Constant */
.codehilite .nd, .codehilitetable .nd { color: #82AAFF } /* Name.Decorator */
.codehilite .ni, .codehilitetable .ni { color: #89DDFF } /* Name.Entity */
.codehilite .ne, .codehilitetable .ne { color: #FFCB6B } /* Name.Exception */
.codehilite .nf, .codehilitetable .nf { color: #82AAFF } /* Name.Function */
.codehilite .nl, .codehilitetable .nl { color: #82AAFF } /* Name.Label */
.codehilite .nn, .codehilitetable .nn { color: #FFCB6B } /* Name.Namespace */
.codehilite .nx, .codehilitetable .nx { color: #EEFFFF } /* Name.Other */
.codehilite .py, .codehilitetable .py { color: #FFCB6B } /* Name.Property */
.codehilite .nt, .codehilitetable .nt { color: #FF5370 } /* Name.Tag */
.codehilite .nv, .codehilitetable .nv { color: #89DDFF } /* Name.Variable */
.codehilite .ow, .codehilitetable .ow { color: #89DDFF; font-style: italic } /* Operator.Word */
.codehilite .pm, .codehilitetable .pm { color: #89DDFF } /* Punctuation.Marker */
.codehilite .w, .codehilitetable .w { color: #EEFFFF } /* Text.Whitespace */
.codehilite .mb, .codehilitetable .mb { color: #F78C6C } /* Literal.Number.Bin */
.codehilite .mf, .codehilitetable .mf { color: #F78C6C } /* Literal.Number.Float */
.codehilite .mh, .codehilitetable .mh { color: #F78C6C } /* Literal.Number.Hex */
.codehilite .mi, .codehilitetable .mi { color: #F78C6C } /* Literal.Number.Integer */
.codehilite .mo, .codehilitetable .mo { color: #F78C6C } /* Literal.Number.Oct */
.codehilite .sa, .codehilitetable .sa { color: #BB80B3 } /* Literal.String.Affix */
.codehilite .sb, .codehilitetable .sb { color: #C3E88D } /* Literal.String.Backtick */
.codehilite .sc, .codehilitetable .sc { color: #C3E88D } /* Literal.String.Char */
.codehilite .dl, .codehilitetable .dl { color: #EEFFFF } /* Literal.String.Delimiter */
.codehilite .sd, .codehilitetable .sd { color: #546E7A; font-style: italic } /* Literal.String.Doc */
.codehilite .s2, .codehilitetable .s2 { color: #C3E88D } /* Literal.String.Double */
.codehilite .se, .codehilitetable .se { color: #EEFFFF } /* Literal.String.Escape */
.codehilite .sh, .codehilitetable .sh { color: #C3E88D } /* Literal.String.Heredoc */
.codehilite .si, .codehilitetable .si { color: #89DDFF } /* Literal.String.Interpol */
.codehilite .sx, .codehilitetable .sx { color: #C3E88D } /* Literal.String.Other */
.codehilite .sr, .codehilitetable .sr { color: #89DDFF } /* Literal.String.Regex */
.codehilite .s1, .codehilitetable .s1 { color: #C3E88D } /* Literal.String.Single */
.codehilite .ss, .codehilitetable .ss { color: #89DDFF } /* Literal.String.Symbol */
.codehilite .bp, .codehilitetable .bp { color: #89DDFF } /* Name.Builtin.Pseudo */
.codehilite .fm, .codehilitetable .fm { color: #82AAFF } /* Name.Function.Magic */
.codehilite .vc, .codehilitetable .vc { color: #89DDFF } /* Name.Variable.Class */
.codehilite .vg, .codehilitetable .vg { color: #89DDFF } /* Name.Variable.Global */
.codehilite .vi, .codehilitetable .vi { color: #89DDFF } /* Name.Variable.Instance */
.codehilite .vm, .codehilitetable .vm { color: #82AAFF } /* Name.Variable.Magic */
.codehilite .il, .codehilitetable .il { color: #F78C6C } /* Literal.Number.Integer.Long */
}
