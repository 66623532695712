@media (prefers-color-scheme: light) {
  pre {
    line-height: 125%;
  }

  td.linenos .normal, span.linenos {
    color: inherit;
    background-color: #0000;
    padding-left: 5px;
    padding-right: 5px;
  }

  td.linenos .special, span.linenos.special {
    color: #000;
    background-color: #ffffc0;
    padding-left: 5px;
    padding-right: 5px;
  }

  .codehilite .hll, .codehilitetable .hll {
    background-color: #ffc;
  }

  .codehilite, .codehilitetable {
    background: #fff;
  }

  .codehilite .c, .codehilitetable .c {
    color: #177500;
  }

  .codehilite .err, .codehilitetable .err {
    color: #000;
  }

  .codehilite .k, .codehilitetable .k {
    color: #a90d91;
  }

  .codehilite .l, .codehilitetable .l {
    color: #1c01ce;
  }

  .codehilite .n, .codehilitetable .n, .codehilite .o, .codehilitetable .o {
    color: #000;
  }

  .codehilite .ch, .codehilitetable .ch, .codehilite .cm, .codehilitetable .cm {
    color: #177500;
  }

  .codehilite .cp, .codehilitetable .cp {
    color: #633820;
  }

  .codehilite .cpf, .codehilitetable .cpf, .codehilite .c1, .codehilitetable .c1, .codehilite .cs, .codehilitetable .cs {
    color: #177500;
  }

  .codehilite .kc, .codehilitetable .kc, .codehilite .kd, .codehilitetable .kd, .codehilite .kn, .codehilitetable .kn, .codehilite .kp, .codehilitetable .kp, .codehilite .kr, .codehilitetable .kr, .codehilite .kt, .codehilitetable .kt {
    color: #a90d91;
  }

  .codehilite .ld, .codehilitetable .ld, .codehilite .m, .codehilitetable .m {
    color: #1c01ce;
  }

  .codehilite .s, .codehilitetable .s {
    color: #c41a16;
  }

  .codehilite .na, .codehilitetable .na {
    color: #836c28;
  }

  .codehilite .nb, .codehilitetable .nb {
    color: #a90d91;
  }

  .codehilite .nc, .codehilitetable .nc {
    color: #3f6e75;
  }

  .codehilite .no, .codehilitetable .no, .codehilite .nd, .codehilitetable .nd, .codehilite .ni, .codehilitetable .ni, .codehilite .ne, .codehilitetable .ne, .codehilite .nf, .codehilitetable .nf, .codehilite .nl, .codehilitetable .nl, .codehilite .nn, .codehilitetable .nn, .codehilite .nx, .codehilitetable .nx, .codehilite .py, .codehilitetable .py, .codehilite .nt, .codehilitetable .nt, .codehilite .nv, .codehilitetable .nv, .codehilite .ow, .codehilitetable .ow {
    color: #000;
  }

  .codehilite .mb, .codehilitetable .mb, .codehilite .mf, .codehilitetable .mf, .codehilite .mh, .codehilitetable .mh, .codehilite .mi, .codehilitetable .mi, .codehilite .mo, .codehilitetable .mo {
    color: #1c01ce;
  }

  .codehilite .sa, .codehilitetable .sa, .codehilite .sb, .codehilitetable .sb {
    color: #c41a16;
  }

  .codehilite .sc, .codehilitetable .sc {
    color: #2300ce;
  }

  .codehilite .dl, .codehilitetable .dl, .codehilite .sd, .codehilitetable .sd, .codehilite .s2, .codehilitetable .s2, .codehilite .se, .codehilitetable .se, .codehilite .sh, .codehilitetable .sh, .codehilite .si, .codehilitetable .si, .codehilite .sx, .codehilitetable .sx, .codehilite .sr, .codehilitetable .sr, .codehilite .s1, .codehilitetable .s1, .codehilite .ss, .codehilitetable .ss {
    color: #c41a16;
  }

  .codehilite .bp, .codehilitetable .bp {
    color: #5b269a;
  }

  .codehilite .fm, .codehilitetable .fm, .codehilite .vc, .codehilitetable .vc, .codehilite .vg, .codehilitetable .vg, .codehilite .vi, .codehilitetable .vi, .codehilite .vm, .codehilitetable .vm {
    color: #000;
  }

  .codehilite .il, .codehilitetable .il {
    color: #1c01ce;
  }
}

@media (prefers-color-scheme: dark) {
  pre {
    line-height: 125%;
  }

  td.linenos .normal, span.linenos {
    color: #37474f;
    background-color: #263238;
    padding-left: 5px;
    padding-right: 5px;
  }

  td.linenos .special, span.linenos.special {
    color: #607a86;
    background-color: #263238;
    padding-left: 5px;
    padding-right: 5px;
  }

  .codehilite .hll, .codehilitetable .hll {
    background-color: #2c3b41;
  }

  .codehilite, .codehilitetable {
    color: #eff;
    background: #263238;
  }

  .codehilite .c, .codehilitetable .c {
    color: #546e7a;
    font-style: italic;
  }

  .codehilite .err, .codehilitetable .err {
    color: #ff5370;
  }

  .codehilite .esc, .codehilitetable .esc {
    color: #89ddff;
  }

  .codehilite .g, .codehilitetable .g {
    color: #eff;
  }

  .codehilite .k, .codehilitetable .k {
    color: #bb80b3;
  }

  .codehilite .l, .codehilitetable .l {
    color: #c3e88d;
  }

  .codehilite .n, .codehilitetable .n {
    color: #eff;
  }

  .codehilite .o, .codehilitetable .o, .codehilite .p, .codehilitetable .p {
    color: #89ddff;
  }

  .codehilite .ch, .codehilitetable .ch, .codehilite .cm, .codehilitetable .cm, .codehilite .cp, .codehilitetable .cp, .codehilite .cpf, .codehilitetable .cpf, .codehilite .c1, .codehilitetable .c1, .codehilite .cs, .codehilitetable .cs {
    color: #546e7a;
    font-style: italic;
  }

  .codehilite .gd, .codehilitetable .gd {
    color: #ff5370;
  }

  .codehilite .ge, .codehilitetable .ge {
    color: #89ddff;
  }

  .codehilite .gr, .codehilitetable .gr {
    color: #ff5370;
  }

  .codehilite .gh, .codehilitetable .gh, .codehilite .gi, .codehilitetable .gi {
    color: #c3e88d;
  }

  .codehilite .go, .codehilitetable .go {
    color: #546e7a;
  }

  .codehilite .gp, .codehilitetable .gp {
    color: #ffcb6b;
  }

  .codehilite .gs, .codehilitetable .gs {
    color: #ff5370;
  }

  .codehilite .gu, .codehilitetable .gu {
    color: #89ddff;
  }

  .codehilite .gt, .codehilitetable .gt {
    color: #ff5370;
  }

  .codehilite .kc, .codehilitetable .kc {
    color: #89ddff;
  }

  .codehilite .kd, .codehilitetable .kd {
    color: #bb80b3;
  }

  .codehilite .kn, .codehilitetable .kn {
    color: #89ddff;
    font-style: italic;
  }

  .codehilite .kp, .codehilitetable .kp {
    color: #89ddff;
  }

  .codehilite .kr, .codehilitetable .kr, .codehilite .kt, .codehilitetable .kt {
    color: #bb80b3;
  }

  .codehilite .ld, .codehilitetable .ld {
    color: #c3e88d;
  }

  .codehilite .m, .codehilitetable .m {
    color: #f78c6c;
  }

  .codehilite .s, .codehilitetable .s {
    color: #c3e88d;
  }

  .codehilite .na, .codehilitetable .na {
    color: #bb80b3;
  }

  .codehilite .nb, .codehilitetable .nb {
    color: #82aaff;
  }

  .codehilite .nc, .codehilitetable .nc {
    color: #ffcb6b;
  }

  .codehilite .no, .codehilitetable .no {
    color: #eff;
  }

  .codehilite .nd, .codehilitetable .nd {
    color: #82aaff;
  }

  .codehilite .ni, .codehilitetable .ni {
    color: #89ddff;
  }

  .codehilite .ne, .codehilitetable .ne {
    color: #ffcb6b;
  }

  .codehilite .nf, .codehilitetable .nf, .codehilite .nl, .codehilitetable .nl {
    color: #82aaff;
  }

  .codehilite .nn, .codehilitetable .nn {
    color: #ffcb6b;
  }

  .codehilite .nx, .codehilitetable .nx {
    color: #eff;
  }

  .codehilite .py, .codehilitetable .py {
    color: #ffcb6b;
  }

  .codehilite .nt, .codehilitetable .nt {
    color: #ff5370;
  }

  .codehilite .nv, .codehilitetable .nv {
    color: #89ddff;
  }

  .codehilite .ow, .codehilitetable .ow {
    color: #89ddff;
    font-style: italic;
  }

  .codehilite .pm, .codehilitetable .pm {
    color: #89ddff;
  }

  .codehilite .w, .codehilitetable .w {
    color: #eff;
  }

  .codehilite .mb, .codehilitetable .mb, .codehilite .mf, .codehilitetable .mf, .codehilite .mh, .codehilitetable .mh, .codehilite .mi, .codehilitetable .mi, .codehilite .mo, .codehilitetable .mo {
    color: #f78c6c;
  }

  .codehilite .sa, .codehilitetable .sa {
    color: #bb80b3;
  }

  .codehilite .sb, .codehilitetable .sb, .codehilite .sc, .codehilitetable .sc {
    color: #c3e88d;
  }

  .codehilite .dl, .codehilitetable .dl {
    color: #eff;
  }

  .codehilite .sd, .codehilitetable .sd {
    color: #546e7a;
    font-style: italic;
  }

  .codehilite .s2, .codehilitetable .s2 {
    color: #c3e88d;
  }

  .codehilite .se, .codehilitetable .se {
    color: #eff;
  }

  .codehilite .sh, .codehilitetable .sh {
    color: #c3e88d;
  }

  .codehilite .si, .codehilitetable .si {
    color: #89ddff;
  }

  .codehilite .sx, .codehilitetable .sx {
    color: #c3e88d;
  }

  .codehilite .sr, .codehilitetable .sr {
    color: #89ddff;
  }

  .codehilite .s1, .codehilitetable .s1 {
    color: #c3e88d;
  }

  .codehilite .ss, .codehilitetable .ss, .codehilite .bp, .codehilitetable .bp {
    color: #89ddff;
  }

  .codehilite .fm, .codehilitetable .fm {
    color: #82aaff;
  }

  .codehilite .vc, .codehilitetable .vc, .codehilite .vg, .codehilitetable .vg, .codehilite .vi, .codehilitetable .vi {
    color: #89ddff;
  }

  .codehilite .vm, .codehilitetable .vm {
    color: #82aaff;
  }

  .codehilite .il, .codehilitetable .il {
    color: #f78c6c;
  }
}

/*# sourceMappingURL=pygmentize-style-classic.css.map */
